import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Landing from "../components/landingSection";
import Feature from "../components/aboutServices";
import ServicesData from "../DB/services-data";
import { Link } from "gatsby";
import Image from "../components/imageProcessing";
import IndexSlides from "../DB/testmonials_slides";
import Reviews from "../components/reviews";
import ImageRight from "../components/imageRight";
import ImageLeft from "../components/imageLeft";
/* eslint react/prop-types: 0 */

function AboutPage() {
	return (
		<Layout>
			<SEO
				keywords={[
					"hostylus",
					"web hosting",
					"hosting website",
					"hosting definition",
					"hosting services",
					"hosting meaning",
					"hosting site",
					"hosting sites",
					"buy hosting",
					"web host",
					"Wordpress hosting",
					"Business hosting",
					"Shared hosting",
					"vps",
					"Dedicated Servers",
					"Domains",
					"web hosting services",
					"hosting provider",
					"domain hosting",
					"website hosting",
					"webhost",
					"website hosting and domain",
					"domain vs hosting",
					"domain versus hosting",
				]}
				title="About"
				image={"/hostylus-card-preview.jpg"}
				description="A fast-developing hosting provider serving customers from all around the globe. We provide an affordable experience for users without cutting down on quality."
			/>
			<Landing
				header="About Hostylus"
				desc="Hostylus is a web hosting solutions start-up that aims to serve all businesses big or small, and all individuals no matter the size of the website. With hosting services that cover, WordPress Hosting, Shared Hosting, Business Hosting, and VPS. Our user-friendly tools, like Plesk; and robust resources to give users the optimal experience to be easily in control without worrying about the performance of the website going down after a limited number of plugins."
				btnURL="/"
				btnText={"Learn more about hostylus"}
				image={
					<Image
						image={"about.png"}
						alt={"a group of men & women standing while dressed up in suits"}
					/>
				}
				hideSecondary_btn={"hidden"}
			/>

			<ImageRight
				image={
					<Image
						image={"ourVision.png"}
						alt={
							"a man smiling happily about hostylus vision  with a laptop in his hands"
						}
					/>
				}
				header={"Our Vision"}
				desc={
					"Our vision is to bring the world closer through technology. So that everyone is able to contribute to the world in their own unique way while being connected 24/7 on all servers of the world and all places where your potential customers could be."
				}
				hideLearnMore={"hidden"}
				hideShopNow={"hidden"}
				btnLink="#"
			/>
			<ImageLeft
				image={
					<Image
						image={"ourMission.png"}
						alt={"a man staring confidently with his arms crossed "}
					/>
				}
				header={"Our Mission"}
				desc={
					"Provide hosting services that best speak with the mind of each and every user of ours, all; for accessible prices that put no limits on your goals, plans, or the actual choice you'll ultimately go for. In short, give you the groundbreaking experience you deserve."
				}
				hideShopNow={"hidden"}
				hideLearnMore={"hidden"}
			/>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3">
				<h2 className="text-4xl text-black font-bold text-center">
					Our hosting services
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3">
					More than what you have in mind, less than what’s in account!
				</h4>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-stretch  mt-6 mobile:grid-cols-1 tablet:grid-cols-2 tablet:px-16 py-8 laptop:px-6">
					{ServicesData.map((feature, key) => (
						<Feature
							key={key}
							image={feature.image}
							header={feature.title}
							alt={feature.imageAlt}
							desc={feature.desc}
							btnLink={feature.btnLink}
						/>
					))}
				</div>
			</section>
			<Reviews
				slides={IndexSlides}
				heading="what clients say about us"
				sub_heading="Clients Reviews"
			/>
			<section className="h-72 bg-blue-600 flex flex-wrap content-center justify-center">
				<div className="max-w-3xl text-center">
					<p className="text-white text-md my-6 mobile:px-4 tablet:px-6">
						Hostylus offers you the best packages to start for the best prices,
						and without compromising on the quality. Despite that we&apos;d like
						to keep you at ease and assure you a 30 day money return if you ever
						decide to cancel.
					</p>
					<Link to={"/#pricing"}>
						<button className="px-6 inline py-3 uppercase bg-white shadow-md ml-1 text-blue-600 rounded-3xl font-medium text-sm">
							get started
						</button>
					</Link>
				</div>
			</section>
		</Layout>
	);
}

export default AboutPage;
