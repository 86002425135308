import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function featureBlock(props) {
  if (props.image.split(".").pop() === "svg")
    return (
      <div className="border-2 h-full rounded-lg p-8">
        <img className="mx-auto" alt={props.alt} src={`/` + props.image} />
        <h2 className="text-center text-xl font-bold">{props.header}</h2>
        <p className="text-center text-md text-gray-500 py-3">{props.desc}</p>
        <Link to={props.btnLink} className="mx-auto  font-semibold block text-center text-blue-600">Learn more</Link>
      </div>
    );
  
}
export default featureBlock;
featureBlock.PropTypes = {
  image: PropTypes.any.isRequired, // must be a string and defined
  header: PropTypes.string.isRequired, // must be a string and defined
  desc: PropTypes.string, // must be a string and defined
  alt: PropTypes.string.isRequired, // must be a string and defined
  btnLink: PropTypes.string, // must be a string and defined
};
