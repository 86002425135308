export default [
  {
    image: "wordpress hosting.svg",
    imageAlt: "WordPress Hosting Services on Hostylus",
    title: "WordPress Hosting",
    desc:"Maximize your experience on WordPress and take a full advantage of all there is to use on your website.",
    btnLink:"/wordpress-hosting"
  },
  {
    image: "business hosting.svg",
    imageAlt: "Business Hosting Services on Hostylus",
    title: "Business Hosting",
    desc:"Your expensive electronic equipment and gadgets may be at risk to an unexpected power surge.",
    btnLink:"/business-hosting"
  },
  {
    image: "shared hosting.svg",
    imageAlt: "Shared Hosting Services on Hostylus",
    title: "Shared Hosting",
    desc:"Feasible plans to give you the push you need to get started today!",
    btnLink:"/shared-hosting"
  },
  // {
  //   image: "student-hosting.svg",
  //   imageAlt: "Student Hosting",
  //   title: "Student Hosting",
  //   desc:"We understand it's not easy for all students to afford pricey hosting plans, so we worked on developing packages that cover your needs with very friendly prices!",
  //   btnLink:"/student-hosting"
  // },
  {
    image: "Cloud VPN.svg",
    imageAlt: "Cloud VPS Services on Hostylus",
    title: "VPS Hosting",
    desc:"Get your own virtual private server for prices starting at $5.99 & enjoy outstanding performance.",
    btnLink:"/vpn"
  },
  {
    image: "Dedicated Servers .svg",
    imageAlt: "Dedicated Servers on Hostylus",
    title: "Dedicated Servers",
    desc:"An entire server to help you go up & beyond.",
    btnLink:"#"
  },
  {
    image: "domains.svg",
    imageAlt: "Domains Services on Hostylus",
    title: "Domains",
    desc:"Choose the domain preference you like.",
    btnLink:"/domains"
  },
];
