
export default [
	{
		name: "Christopher Davis",
		message:
			"Hostylus offers an excellent balance of affordability and performance. Their hosting plans are competitively priced, and the speed of their servers is truly impressive. I highly recommend their services.",
		image: "/christopher-davis.webp",
	},
	{
		name: "Dr Ahmed Safad",
		message:
			"Throughout the process of purchasing new hosting and migrating my site, I had numerous questions. I spoke with three different Hostylus representatives, and each one was incredibly helpful, friendly, and patient. They provided exceptional service!",
		image: "/dr-ahmad-safad.jpg",
	},
	{
		name: "Sarah Horn",
		message: "Hostylus provides outstanding customer service and offers a wide range of powerful tools to manage your hosting effortlessly.",
		image: "/sarah-thompson.webp",
	},
	{
		name: "Ibraheem Hatoqai",
		message:
			"I've been benefiting from Hostylus' hosting service for 4 months, and I have never experienced any website downtime. The speed from the server end is excellent, and the pricing is reasonable. Keep up the great work, guys!",
		image: "/ibrahim.webp",
	},
	{
		name: "Robert Anderson",
		message: "Hostylus is the most valuable business resource we have ever invested in. We have absolutely no regrets – it's the perfect solution for our company's needs.",
		image: "/robert-anderson.webp",
	},
	{
		name: "John Smith",
		message:
			"I greatly appreciate the transparency of Hostylus' pricing structure and the fact that they offer unlimited bandwidth with their hosting plans. It's a fantastic value for the price.",
		image: "/john-smith.webp",
	},
	{
		name: "Maen Ozrail",
		message:
			"I have been using Hostylus Hosting for a couple of months now, and their service is consistently reliable. Whenever I have encountered an issue, their support team has been incredibly fast in responding to my tickets. They take corrective action after the problem has been explained only once, whereas other providers often require several explanations. I've never experienced better service.",
		image: "/Maen-Ozrail-min.webp",
	},
	{
		name: "Michael Johnson",
		message: "Hostylus is a top-notch hosting service that I highly recommend. The quality of their service is truly impressive.",
		image: "/michael-johnson.webp",
	},
	{
		name: "Eliel Arkki",
		message: "Hostylus delivers exceptional customer service and provides a comprehensive set of tools to streamline your hosting experience.",
		image: "/Eliel-Arkki-min.webp",
	},
	{
		name: "Abdullah Abu Khalaf",
		message:
			"Honestly, Hostylus is an excellent hosting service. I have been using their service for several months now, and I am extremely satisfied with the results. The pricing is also very reasonable. Thank you so much, Hostylus. Best regards.",
		image: "/Abdullah-Abu-Khalaf.webp",
	},
	{
		name: "Laura Thompson",
		message: "I had numerous questions about purchasing new hosting and migrating my site. Throughout the process, I spoke with three different Hostylus team members, and each one was incredibly helpful, friendly, and patient. They did an excellent job assisting me!",
		image: "/daniel-thompson.webp",
	},
	{
		name: "Harry Naruto",
		message:
			"Hostylus is the most valuable business resource we have ever purchased. We have absolutely no regrets – it's the ideal solution for our company's requirements.",
		image: "/Harry Naruto.webp",
	}
];